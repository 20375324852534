import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const initReviewsCaro = () => {
	var caro = AutomatitCarousel({
		element: document.getElementById('reviews_container'),
		useChevrons: false,
		speed: 300,
		intervalTiming: 7000
	});
    
	document.querySelector('#reviews_wrapper .left').addEventListener('click', function(){
		caro.slideLeft();
	});
	document.querySelector('#reviews_wrapper .right').addEventListener('click', function(){
		caro.slideRight();
	});
};