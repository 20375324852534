export const initMobileNav = () => {	
	const menuToggles = document.querySelectorAll('.mobile_menu_toggle');
	menuToggles.forEach(menuToggle => {
		menuToggle.addEventListener('click', function () {
			document.querySelector('#header_right').switchAttribute('state', 'open', 'closed');
		});
	});
};

export const doubleClick = () => {
	[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
		el.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if (!el.hasAttribute('clicked') && window.screen.width > 1000) {
					e.preventDefault();
					el.setAttribute('clicked', true);
				}
			}
		});
	});
};