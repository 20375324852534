import { initCareersList } from './careers.js';
import { contactSubmit } from "./contact.js";
import { initFacilityCaro, initFacilityMapToggle, setActiveFilter, viewMore, viewMoreFeatures, facilityFAQS, facilityInfoToggle} from "./facility";
import { doubleClick, initMobileNav } from "./header";
import { initHelp } from "./help";
import { locationsFilter } from "./home.js";
import { initMap } from "./map";
import "./polyfills";
import { initReviewsCaro } from "./reviews";

initMobileNav();
doubleClick();

if(document.querySelector('.careers_list')) {
	initCareersList();
}

if (document.getElementById('accordion')) {
	const acc_items = document.querySelectorAll('.accordion_title');
	const acc_content = document.querySelectorAll('.accordion_content');
	const acc_arrows = document.querySelectorAll('.acc_arrow');

	acc_items.forEach(item => {
		item.addEventListener('click', () => {
			acc_content.forEach(content => {
				content.classList.remove('active');
			});
			acc_arrows.forEach(arrow => {
				arrow.classList.remove('active');
			});
			item.nextElementSibling.classList.add('active');
			item.childNodes[0].classList.add('active');
		});
	});
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById("help_list")) {
	initHelp();
}
if (document.querySelector("#home_locations_list")) {
	locationsFilter();
}

if (document.querySelector("#map")) {
	initMap();
}

if (document.querySelector("#reviews_container")) {
	initReviewsCaro();
}

if (document.querySelector("#facility")) {
	setActiveFilter();
	initFacilityCaro();
	initMap("facility_map", ".facility_map_source");
	initFacilityMapToggle();
	viewMore.init();
	viewMoreFeatures.init();
	facilityFAQS.init();
	facilityInfoToggle.init();
}

if(document.querySelector('.overlay_options')){
	
	/* eslint-disable */
	$('.rates_button').click(function(e) {
		$('.rates_overlay').toggleClass("open");

		var button = e.currentTarget;
	
		if (!button) {
			return;
		}
	
		const scrollHeight = $(window).scrollTop();
	
		var groupIdentifier = button.getAttribute('data-group');
	
		var overlay = $(".overlay_options[data-group='" + groupIdentifier + "']");
	
		var overlayHeight = overlay.height();
	
		//var overlaySelHeight = $('.overlay_selection').height();
	
		if (overlay.length) {
			//>> activate the overlay
			overlay.toggleClass("open");
		} else {
			toRatesHandler(button, false);
		}
	});
	
	var toRatesHandler = (el, overlay) => {
	
		$('#back_button').click(function() {
	
			if (overlay == false) {
				$('.rates_overlay').removeClass("open");
				//$('.overlay_selection').removeClass("open");
			} else {
				//$('.overlay_selection').removeClass("open");
				$(overlay).toggleClass('open');
				//$('.rates_overlay').addClass("open");
				overlay = false;
			}
		});
		
		if (el.hasAttribute('data-inquiry-only')) {
			location.href = el.getAttribute('data-inquire');
		} else {
			var rentUrl = el.getAttribute('data-rent');
			var resUrl = el.getAttribute('data-res');
			$('.rent_overlay').attr("href", rentUrl);
			$('.reserve_overlay').attr("href", resUrl);
			$('.overlay_options.open').toggleClass("open");
			$('.rates_overlay').toggleClass("open");
			//window.open(rentUrl,'_self');
			//window.open(resUrl,'_self');
		}
	}
	
	$('.to_rates').click(function(e) {
		const openOverlay = $('.overlay_options.open');
		toRatesHandler(e.currentTarget, openOverlay);
		//$('.overlay_selection').addClass('open');
	});
	
	//>> close the rates if the overlay behind it is clicked
	$('.rates_overlay').click(function() {
		$('.rates_overlay').removeClass("open");
		$('.overlay_options').removeClass("open");
		//$('.overlay_selection').removeClass("open");
	});
	
	//>> close rates overlay if "X" is clicked
	$('.overlay_close').click(function() {
		$('.rates_overlay').removeClass("open");
		$('.overlay_options').removeClass("open");
		//$('.overlay_selection').removeClass("open");
	});
}