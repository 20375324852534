import { AutomatitCarousel } from "./automatit_carousel.m";

export const setActiveFilter = () => {
	const filters = document.querySelectorAll(".filter");
	const checkboxes = document.querySelectorAll(".filter");
	const rows = document.querySelectorAll(".unit_row");
	const no_units = document.getElementById("no_units");

	for (let i = 0; i < filters.length; i++) {
		filters[i].addEventListener("click", function () {
			for (let j = 0; j < filters.length; j++) {
				filters[j].removeAttribute("active");
				checkboxes[j].removeAttribute("active");
			}

			const activeFilter = filters[i].getAttribute("size");
			const checkbox = document.querySelector(
				`.filter[size="${activeFilter}"]`
			);

			let numUnits = 0;

			rows.forEach((row) => {
				row.setAttribute("state", "hide");
				if (
					row.getAttribute("size") === activeFilter ||
          activeFilter === "all"
				) {
					row.setAttribute("state", "show");
					numUnits++;
				}
			});

			if (numUnits === 0) {
				no_units.style.display = "flex";
			} else {
				no_units.style.display = "none";
			}

			checkbox.setAttribute("active", true);
			filters[i].setAttribute("active", true);
		});
	}
};

export const initFacilityCaro = () => {
	const options = {
		element: document.querySelector("#facility_caro"),
		images: window.facCaroImgs,
		imagesAsBackgrounds: false,
		showDots: true,
		useChevrons: false,
	};

	if (window.facCaroImgs.length === 1) {
		options["intervalTiming"] = false;
	}

	AutomatitCarousel(options);
};

export const initFacilityMapToggle = () => {
	const caroBtnsWrapper = document.getElementById("facility_caro_buttons");
	const caroBtns = document.querySelectorAll(".facility_caro_button");
	const facility_caro = document.getElementById("facility_caro");
	const facility_map_wrapper = document.getElementById("facility_map_wrapper");
	const facility_caro_wrapper = document.getElementById("facility_caro_wrapper");

	caroBtns.forEach(caroBtn => {
		caroBtn.addEventListener("click", function () {
			facility_caro_wrapper.classList.toggle("show_map");
			facility_caro.classList.toggle("hide");
			facility_map_wrapper.classList.toggle("show");
			caroBtnsWrapper.classList.toggle("gallery_display");
		});
	});
};

export const viewMore = (function(){

	let my = {};

	function _viewMore() {
		const viewMoreToggle = document.getElementById('view_more');
		const allUnits = document.querySelectorAll('.unit_row');
		const unitRows = document.querySelectorAll('#facility_rates .unit_row');
		const filters = document.querySelectorAll('#filters_container .filter');

		const hideUnits = (unitsArray) => {
			for (let i = 0; i < unitsArray.length; i++) {
				if(i > 4){
					unitsArray[i].setAttribute("state", "hide");
				}
			}
		};

		if(allUnits.length <= 5){
			viewMoreToggle.style.display = 'none';
		} else {
			
			hideUnits(unitRows);
	
			viewMoreToggle.addEventListener('click', function(){
				allUnits.forEach(unit => {
					unit.setAttribute("state", "show");
				});
				viewMoreToggle.style.display = 'none';
			});

			filters.forEach(filter => {
				filter.addEventListener('click', function(){
					viewMoreToggle.style.display = 'none';
				});
			});
		}

	}

	my.init = function(){
		_viewMore();
	};

	return my;
})();

export const viewMoreFeatures = (function(){

	let my = {};

	function _viewMoreFeatures() {
		const viewMoreToggle = document.getElementById('view_more_features');
		const allFeatures = document.querySelectorAll('#facility_features .feature');
		const features = document.querySelectorAll('#facility_features .feature');

		const hideUnits = (featsArray) => {
			for (let i = 0; i < featsArray.length; i++) {
				if(i > 13){
					featsArray[i].classList.add('hide');
				}
			}
		};

		if(allFeatures.length <= 14){
			viewMoreToggle.style.display = 'none';
		} else {
			
			hideUnits(features);
	
			viewMoreToggle.addEventListener('click', function(){
				allFeatures.forEach(feat => {
					feat.classList.remove('hide');
				});
				viewMoreToggle.style.display = 'none';
			});
		}

	}

	my.init = function(){
		_viewMoreFeatures();
	};

	return my;
})();

export const facilityFAQS = (function(){

	let my = {};
	
	function _facilityFAQS() {
		const facilityItems = document.querySelectorAll('.faq_item');

		const removeExpand = (arrayitem) => {
			arrayitem.forEach(x => {
				x.classList.remove('expand');
			});
		};
		
		facilityItems.forEach(facilityItem => {
			facilityItem.addEventListener('click', function(e){
				let clickedItem = e.currentTarget;
				if(clickedItem.classList.contains('expand')){
					clickedItem.classList.remove('expand');
				} else {
					removeExpand(facilityItems);
					clickedItem.classList.add('expand');
				}
			});
		});
	}

	my.init = function(){
		_facilityFAQS();
	};

	return my;
})();

export const facilityInfoToggle = (function(){

	let my = {};
	
	function _facilityInfoToggle() {
	
		const infoToggle = document.getElementById('facility_info_toggle');
		
		const info = document.getElementById('facility_info');

		infoToggle.addEventListener('click', function(){
			info.classList.toggle('expand');
		});

	}

	my.init = function(){
		_facilityInfoToggle();
	};

	return my;
})();